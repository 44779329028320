import React from "react";
import NonAuthLayout from "../../../Layout/NonAuthLayout";
import "./Components/Index.css";
import CreateNotification from "./Components/CreateNotification";

const ManageNotification = () => {

  return (
    <NonAuthLayout>
      <section className="manageNotifications position-relative py-3">
              <div className="filterWrp mb-3 px-lg-5 px-3 d-flex align-items-center justify-content-between gap-10">
                <h2 className="m-0 fw-bold">Manage Notifications</h2>
              </div>
              <div className="row px-lg-5 px-3 d-flex align-items-center justify-content-between gap-10">
                <CreateNotification />
              </div>
      </section>
    </NonAuthLayout>
  );
};

export default ManageNotification;
