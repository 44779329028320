import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Table, Row, Form } from "react-bootstrap";
import { getAllCmsPage, DeleteCmsPage } from "../../../redux/states/cms/thunk";
import NonAuthLayout from "../../../Layout/NonAuthLayout";
import { format_date, parseCamelKey } from "../../../utils/parseKey";
import { useNavigate } from "react-router-dom";
import { Box, Button, Text, useToast,  } from "@chakra-ui/react";
import "./Components/Index.css"
import { confirmAlert } from "react-confirm-alert";

const ManageCms = () => {
  const dispatch = useDispatch();
  const { cms, loading, error } = useSelector((state) => state.cms);
  const navigate = useNavigate();

  const fetchCmsPage = async () => {
    dispatch(getAllCmsPage());
  };

  useEffect(() => {
    fetchCmsPage();
  }, [dispatch]);



  const handleCreateCms = () => {
    navigate(`/create-cms`);
  };

  const handleUpdateCms = (_id) => {
    navigate(`/update-cms/${_id}`);
  };
  const handleReadCms = (_id) => {
    navigate(`/read-cms/${_id}`);
  };


  const handleDeletecms = (_id) => {
      confirmAlert({
        title: "Confirm Action",
        message: "Are you sure you want to delete this Cms Page?",
        buttons: [
          {
            label: "Yes",
            onClick: () => 
              dispatch(
                DeleteCmsPage(_id),
            ),
          },
          {
            label: "No",
          },
        ],
      });
    };

  return (
   <>
   <NonAuthLayout>
      <section className="manageStories position-relative py-3">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
            <div className="filterWrp mb-3 px-lg-5 px-3 d-flex align-items-center justify-content-between gap-10">
              <h2 className="m-0 fw-bold">Manage Cms Pages</h2>
              <button
                className="button-create-cms"
                variant="danger"
                onClick={handleCreateCms}
              >
                Create Cms Page
              </button>
            </div>
            </Col>

            <Col>
              {cms?.length > 0 ? (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Cms title</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cms?.map((cmss, idx) => (
                      <tr key={cmss._id}>
                        <td>{idx + 1 || "N/A"}</td>
                        <td>{cmss?.title || "N/A"}</td>
                        <td
                          style={{
                           color: cmss.status === "active" ? "rgb(39, 117, 0)" : "rgb(217, 26, 26)" 
                            }}>{cmss?.status || "N/A"}</td>
                        <td>
                          <button
                           className="button-read-cms"
                            variant="danger"
                            onClick={() => handleReadCms(cmss._id)}>
                            {"Read"}
                            </button>
                          <button
                           className="button-update-cms"
                            variant="danger"
                            onClick={() => handleUpdateCms(cmss._id)}>
                            {"Update"}
                            </button>
                          <button
                           className="button-delete-cms"
                            variant="danger"
                            onClick={() => handleDeletecms(cmss._id)}>
                            {"Delete"}
                            </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h4 className="my-5 text-center w-100">Not Data Found</h4>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </NonAuthLayout>
</>
  );
};

export default ManageCms;
