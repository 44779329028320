import { createSlice } from "@reduxjs/toolkit";
import { getUserAnalytics } from "./thunk";

const initialState = {
  userAnalytics: {},
  vehicleAnalytics: {},
};

const slice = createSlice({
  name: "dashboard",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserAnalytics.pending, (state, action) => {})
      .addCase(getUserAnalytics.fulfilled, (state, action) => {
        state.userAnalytics = action.payload;
      })
      .addCase(getUserAnalytics.rejected, (state, action) => {});
  },
});

export default slice.reducer;
