import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../../../../redux/states/user/thunk";

const BarChart = ({ graphData, color }) => {
  const dispatch = useDispatch();
  // const { users, loading, error, totalCount } = useSelector((state) => state.users);
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Users",
        data: [], // Initial empty data array
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        stacked: false,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 6,
          dataLabels: {
            total: {
              enabled: false,
            },
          },
        },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
      colors: [color || "#ff0000"],
    },
  });

  // useEffect(() => {
  //   if (!users.length && !loading) {
  //     const fetchUsers = async () => {
  //       await dispatch(
  //         getAllUsers({
  //           page: 1,
  //           limit: 100,
  //           order: "",
  //           orderBy: "",
  //           gender: "",
  //           status: "",
  //           search: "",
  //         })
  //       );
  //     };
  //     fetchUsers();
  //   }
  // }, [dispatch, users.length, loading]);

  // useEffect(() => {
  //   if (users?.length > 0) {
  //     const monthCounts = Array(12).fill(0);

  //     users.forEach((user) => {
  //       const registrationDate = new Date(user.registrationDate);
  //       if (!isNaN(registrationDate)) {
  //         const month = registrationDate.getMonth();
  //         monthCounts[month] += 1; 
  //       }
  //     });

  //     setChartData((prev) => ({
  //       ...prev,
  //       series: [
  //         {
  //           name: "Users",
  //           data: monthCounts, 
  //         },
  //       ],
  //     }));
  //   }
  // }, [users]);

  // console.log(users);
// console.log(monthCounts);


  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default BarChart;
