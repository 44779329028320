import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const getAllConfigs = createAsyncThunk(
  "configs/getAllConfigs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "/utility/configs"            
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const UpdateConfigs = createAsyncThunk(
  "configs/UpdateConfigs",
  async ({ ...categoryData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/admin/config/update`, categoryData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

