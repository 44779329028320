import { createSlice } from "@reduxjs/toolkit";
import { ReadQuries } from "./thunk";

const initialState = {
  quriessingle: [],
  loading: false,
  error: null,
};

const singleSliceView = createSlice({
  name: "quriessingle",
  initialState : {
    quriessingle: [],
    loading: false,
    error: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
   
        //   ya wala slice ReadQuries  krne k lia h
         .addCase(ReadQuries.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(ReadQuries.fulfilled, (state, action) => {
            state.loading = false;
            state.quriessingle = action.payload.data.records || [];
          })
          .addCase(ReadQuries.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          }) 
       
  }
});

export const { } = singleSliceView.actions;

export default singleSliceView.reducer;
