import { createSlice } from "@reduxjs/toolkit";
import { getAllCmsPage,CreateCmsPage,DeleteCmsPage, getSinglePage, UpdateCmsPage } from "./thunk";

const initialState = {
  cms: [],
  loading: false,
  error: null,
};

const cmsSlice = createSlice({
  name: "cms",
  initialState : {
    cms: [],
    loading: false,
    error: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    //ya wala slice all cms page list ko get krne k lia tha 
      .addCase(getAllCmsPage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllCmsPage.fulfilled, (state, action) => {
        state.loading = false;
        state.cms = action.payload.data;
      })
      .addCase(getAllCmsPage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })      
    //ya wala slice single cms ko get krne k lia tha 
    //   .addCase(getSinglePage.pending, (state) => {
    //     state.loading = true;
    //     state.error = null;
    //   })
    //   .addCase(getSinglePage.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.cms = action.payload.data;
    //   })
    //   .addCase(getSinglePage.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload;
    //   })      
        //   ya wala slice cms create krne k lia h
         .addCase(CreateCmsPage.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(CreateCmsPage.fulfilled, (state, action) => {
            state.loading = false;
            state.cms.push(action.payload);
          })
          .addCase(CreateCmsPage.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          }) 
          //aab sa DeleteCmsPage ka slice start
                .addCase(DeleteCmsPage.pending, (state) => {
                  state.loading = true;
                  state.error = null;
                })
                .addCase(DeleteCmsPage.fulfilled, (state, action) => {
                  state.loading = false;
                  state.cms = state.cms.filter(
                    (cmss) => cmss._id !== action.payload._id
                  );
                })
                .addCase(DeleteCmsPage.rejected, (state, action) => {
                  state.loading = false;
                  state.error = action.payload;
                })
                  //aab sa UpdateCmsPage ka slice start
                  .addCase(UpdateCmsPage.pending, (state) => {
                    state.loading = true;
                    state.error = null;
                  })
                  .addCase(UpdateCmsPage.fulfilled, (state, action) => {
                    state.loading = false;
                    state.cms = state.cms.filter(
                      (cms) => cms._id !== action.payload._id
                    );
                  })
                  .addCase(UpdateCmsPage.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action.payload;
                  })

  }
});

export const { } = cmsSlice.actions;

export default cmsSlice.reducer;
