import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateFaqsThunk, UpdateFaqs } from "../../../../redux/states/faqs/thunk";
import NonAuthLayout from "../../../../Layout/NonAuthLayout";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CreateFaqs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: faqId } = useParams();
  const { faqs, loading, error } = useSelector((state) => state.faqs);

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [status, setStatus] = useState("active");

  // Populate form fields when editing an existing FAQ
  useEffect(() => {
    if (faqId) {
      const faqsData = faqs.find((faq) => faq._id === faqId);
      if (faqsData) {
        setQuestion(faqsData.question || "");
        setAnswer(faqsData.answer || "");
        setStatus(faqsData.status || "active");
      }
    } else {
      setQuestion("");
      setAnswer("");
      setStatus("active");
    }
  }, [faqId, faqs]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const faqsData = {
      question,
      answer,
      status,
    };

    try {
      if (faqId) {
        await dispatch(UpdateFaqs({ faqId, ...faqsData })).unwrap();
        console.log("FAQ updated successfully");
      } else {
        await dispatch(CreateFaqsThunk(faqsData)).unwrap();
        console.log("FAQ created successfully");
        setQuestion("");
        setAnswer("");
        setStatus("active");
      }
      navigate("/manage-faqs");
    } catch (err) {
      console.error("Error saving FAQ:", err);
    }
  };

  return (
    <NonAuthLayout>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h3>{faqId ? "Update FAQ" : "Create FAQ"}</h3>

            {loading ? (
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="faqsTitle"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder="Enter Question"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="statusDropdown" className="form-label">
                    Status
                  </label>
                  <select
                    className="form-select"
                    id="statusDropdown"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>

                <div className="mb-3">
                  <ReactQuill
                    value={answer}
                    onChange={setAnswer}
                    style={{ overflowY: "auto" }}
                    className="react-quill-content-editor"
                  />
                </div>

                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading
                    ? "Saving..."
                    : faqId
                    ? "Update FAQ"
                    : "Create FAQ"}
                </button>
              </form>
            )}

            {error && <div className="alert alert-danger mt-3">{error}</div>}
          </div>
        </div>
      </div>
    </NonAuthLayout>
  );
};

export default CreateFaqs;
