import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateCmsPage,UpdateCmsPage,getSinglePage } from "../../../../redux/states/cms/thunk";
import NonAuthLayout from "../../../../Layout/NonAuthLayout";
import { useNavigate, useParams } from "react-router-dom";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";


const CreateCms = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: pageId } = useParams();
    const { cms, loading, error } = useSelector((state) => state.cms);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [status, setStatus] = useState("active");

    
    //   const fetchSingleCmsPage = async () => {
    //     dispatch(getSinglePage(pageId));
    //   };
    
    //   useEffect(() => {
    //     fetchSingleCmsPage();
    //   }, [dispatch, pageId]);

      
  useEffect(() => {
    if (pageId) {
      const cmsData = cms.find((cmss) => cmss._id === pageId);
      if (cmsData) {
        setTitle(cmsData.title || "");
        setContent(cmsData.content || "");
        setStatus(cmsData.status || "active");
      }
    } else {
      setTitle("");
      setContent("");
      setStatus("active");
    }
  }, [pageId, cms]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const cmsData = {
      pageId: pageId,
      title,
      content,
      status,
    };

    if (pageId) {
      dispatch(UpdateCmsPage({ _id: pageId, ...cmsData }))
        .unwrap()
        .then(() => {
          console.log("cms updated successfully");
          navigate("/manage-csm");
        })
        .catch((err) => {
          console.error("Error updating cms:", err);
        });
    } else {
      dispatch(CreateCmsPage(cmsData))
        .unwrap()
        .then(() => {
          setTitle("");
          setContent("");
          setStatus("active");
          navigate("/manage-cms");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
  <>
    <NonAuthLayout>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h3>{pageId ? "Update CMS Page" : "Create CMS Page"}</h3>

            {loading ? (
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="cmsTitle"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Enter CMS title"
                    required
                  />
                </div>
                <div className="mb-3">
                 <label htmlFor="statusDropdown" className="form-label">Status
                 </label>
                 <select
                   className="form-select"
                   id="statusDropdown"
                   value={status}
                   onChange={(e) => setStatus(e.target.value)}
                 >
                   <option value="active">Active</option>
                   <option value="inactive">Inactive</option>                                                                      
                 </select>
               </div>
                <div className="mb-3">
                  <ReactQuill
                    value={content}
                    // onChange={(e) => setContent(e.target.value)}
                    onChange={setContent}
                    className="react-quill-content-editor"
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading
                    ? "Saving..."
                    : pageId
                    ? "Update CMS Page"
                    : "Create CMS Page"}
                </button>
              </form>
              
            )}

            {error && <div className="alert alert-danger mt-3">{error}</div>}
          </div>
        </div>
      </div>
    </NonAuthLayout>
  </>
  );
};

export default CreateCms;
