import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const SendNotifications = createAsyncThunk(
  "notification/SendNotifications",
  async (cmsData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/admin/cms/add",cmsData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

