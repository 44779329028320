import { createSlice } from "@reduxjs/toolkit";
import { getDashboardAnalytics, imageUpload } from "./thunk";

const initialState = {
  analytics: {},
};

const commonSlice = createSlice({
  name: "commonSlice",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(imageUpload.pending, (state, action) => {})
      .addCase(imageUpload.fulfilled, (state, action) => {})
      .addCase(imageUpload.rejected, (state, action) => {});
  },
});

export default commonSlice.reducer;
