import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Table, Row, Form } from "react-bootstrap";
import { getAllUsers, UserBlockorUnblock } from "../../../redux/states/user/thunk";
import NonAuthLayout from "../../../Layout/NonAuthLayout";
import { format_date, parseCamelKey } from "../../../utils/parseKey";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import MyPagination from "../../../components/common/myPagination";
import { FaRegEye } from "react-icons/fa";

const ManageUsers = () => {
  const dispatch = useDispatch();
  const { users, loading, error, totalCount } = useSelector((state) => state.users);
  const navigate = useNavigate();
  const [paginationDetails, setPaginationDetails] = useState({ page: 1, limit: 20 });
  const [filters, setFilters] = useState({
    gender: "",
    status: "",
    search: "",
  });
  
 const fetchUsers = async () => {
  const request = { ...paginationDetails, ...filters };
  await dispatch(getAllUsers(request));
};

useEffect(() => {
  fetchUsers();
}, [filters, paginationDetails]);


const handleFilterChange = (e) => {
  const { name, value } = e.target;
  setFilters((prev) => ({
    ...prev,
    [name]: value,
  }));
  setPaginationDetails((prev) => ({
    ...prev,
    page: 1,
  }));
};


  const ViewUser = (_id) => {
    navigate(`/singleuser/${_id}`);
  };

  const handleBlockUnblock = (userId, currentStatus) => {
    confirmAlert({
      title: "Confirm Action",
      message: `Are you sure you want to ${currentStatus === "active" ? "block" : "unblock"} this user?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => dispatch(UserBlockorUnblock({ targetUserId: userId, currentStatus })),
        },
        {
          label: "No",
        },
      ],
    });
  };



  return (
 <>
     <NonAuthLayout>
      <section className="manageUsers position-relative py-3">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="filterWrp mb-3 px-lg-5 px-3 d-flex align-items-center flex-wrap justify-content-between gap-10">
                <div>
                  <h2 className="m-0 fw-bold">Manage Users</h2>
                  <h6 className="muted">Users Count {":=>"} {totalCount}</h6>
                </div>
                <div className="d-flex gap-2">
                  {/* Gender Filter */}
                  <Form.Select
                    name="gender"
                    value={filters.gender}
                    onChange={handleFilterChange}
                    className="me-2"
                  >
                    <option value="">All Genders</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Form.Select>
                  {/* Status Filter */}
                  <Form.Select
                    name="status"
                    value={filters.status}
                    onChange={handleFilterChange}
                  >
                    <option value="">All Status</option>
                    <option value="active">Active</option>
                    <option value="blocked">Blocked</option>
                    <option value="inactive">Inactive</option>
                  </Form.Select>
                </div>
                <div className="searchForm position-relative icon-with-text">
                    <input
                      name="search"
                      value={filters.search}
                      onChange={handleFilterChange}
                      type="text"
                      placeholder="Search"
                      className="form-control rounded-pill"
                    />
                    <span className="icn position-absolute">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                      >
                        <path
                          d="M7.1875 13.125C10.4667 13.125 13.125 10.4667 13.125 7.1875C13.125 3.90831 10.4667 1.25 7.1875 1.25C3.90831 1.25 1.25 3.90831 1.25 7.1875C1.25 10.4667 3.90831 13.125 7.1875 13.125Z"
                          stroke="#9DA3BB"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.75 13.75L12.5 12.5"
                          stroke="#9DA3BB"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
              </div>
            </Col>

            <Col>
              <Table className="table mt-4" striped bordered hover responsive>
                <thead className="border-0">
                  <tr className="secondaryColor">
                    <th className="border-0 p-3">S.No.</th>
                    <th className="border-0 p-3">Image</th>
                    <th className="border-0 p-3">Name</th>
                    <th className="border-0 p-3">Email</th>
                    <th className="border-0 p-3">Mobile</th>
                    <th className="border-0 p-3">DOB</th>
                    <th className="border-0 p-3">Gender</th>
                    <th className="border-0 p-3">Bio</th>
                    <th className="border-0 p-3">Country</th>
                    <th className="border-0 p-3">User Type</th>
                    <th className="border-0 p-3">Registered At</th>
                    <th className="border-0 p-3">User View</th>
                    <th className="border-0 p-3 text-center">Status</th>
                    <th className="border-0 p-3 text-center">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {users?.length > 0 ? (
                    users.map((user, idx) => (
                      <tr key={user._id}>
                        <td className="p-3">{idx + 1}</td>
                        <td className="p-3">
                          {user.avatar && user.avatar._id ? (
                            <img
                              src={`${user.avatar?.url} || ./../../../assets/images/dp.jpg`}
                              alt={user.name.slice(0, 6)}
                              style={{ width: "40px", height: "40px", borderRadius: "50%", border: "1px solid black" }}
                            />
                          ) : (
                            <img
                              src="./../../../assets/images/dp.jpg"
                              alt="Default Avatar"
                              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                            />
                          )}
                        </td>
                        <td className="p-3">{user.name || "not updated"}</td>
                        <td className="p-3">{user.email || "not updated"}</td>
                        <td className="p-3">{user.mobile || "not updated"}</td>
                        <td className="p-3">{user.birth_date || "not updated"}</td>
                        <td className="p-3">{user.gender || "not updated"}</td>
                        <td className="p-3">{user.bio || "not updated"}</td>
                        <td className="p-3">{user.country?.name || "not updated"}</td>
                        <td className="p-3">{user.userType || "not updated"}</td>
                        <td className="p-3">{format_date(user.createdAt) || "not updated"}</td>
                        <td className="p-3">
                          <button
                            className="py-1 px-1 text-white rounded-pill view-user"
                            style={{ background: "#4ed3ff" }}
                            onClick={() => ViewUser(user._id)}
                          >
                            <FaRegEye className="eye-icon" />
                          </button>
                        </td>
                        <td className="p-3 text-center">
                          <span
                            className={`py-2 px-3 text-white rounded-pill ${
                              user.status === "active"
                                ? "bg-success"
                                : user.status === "blocked"
                                ? "bg-danger"
                                : user.status === "inactive"
                                ? "bg-secondary"
                                : ""
                            }`}
                          >
                            {user.status === "inactive"
                              ? "Email not verified"
                              : parseCamelKey(user.status)}
                          </span>
                        </td>
                        <td className="p-3 text-center">
                          <button
                            className="py-1 px-1 text-white rounded-pill"
                            style={{ background: user.status === "active" ? "#ff4e4e" : "#4ed3ff" }}
                            onClick={() => handleBlockUnblock(user._id, user.status)}
                          >
                            {user.status === "active" ? "Block" : "Unblock"}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={14}>
                      <h4 className="my-5 text-center w-100">Not Data Found</h4>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
            <MyPagination
                paginationDetails={paginationDetails}
                setPaginationDetails={setPaginationDetails}
                totalPosts={totalCount}
              />
          </Row>
        </Container>
      </section>
    </NonAuthLayout>
     
 </>
  );
};

export default ManageUsers;
