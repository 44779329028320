import Login from "./AuthPages/Login/Index";
import ResetPassword from "./AuthPages/forget/resetPassword";
// import Register from "./AuthPages/register";
import SetPassword from "./AuthPages/setPassword";

const publicRoutes = [
  { path: "/", component: <Login /> },
  { path: "/login", component: <Login /> },
  // { path: "/register", component: <Register /> },
  { path: "/resetPassword", component: <ResetPassword /> },
  { path: "/set/password", component: <SetPassword /> },
];

export default publicRoutes;
