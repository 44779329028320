import { createSlice } from "@reduxjs/toolkit";
import { SingleStoryComments } from "./thunk";

const initialState = {
    comments:[],
    loading: false,
  error: null,
  totalCount:0,
};

const storyCommentSlice = createSlice({
  name: "comments",
  initialState : {
    comments:[],
    loading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
          // ya wala slice Single Story k comments get krne k lia tha 
          .addCase(SingleStoryComments.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(SingleStoryComments.fulfilled, (state, action) => {
            state.loading = false;
            state.comments = action.payload.data.records || [];
            state.totalCount = action.payload.data.totalCount || 0;
            console.log("Payload received:", action.payload);
            console.log("Updated comments:", state.comments);
            console.log("Updated totalCount:", state.totalCount);
          })
          .addCase(SingleStoryComments.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })   

  }
});

export const { } = storyCommentSlice.actions;

export default storyCommentSlice.reducer;
