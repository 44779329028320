import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Table, Row, Form } from "react-bootstrap";
import { SingleStoryReactions } from "../../../../../redux/states/story/thunk";
import NonAuthLayout from "../../../../../Layout/NonAuthLayout";
import { format_date } from "../../../../../utils/parseKey/index";
import { useNavigate, useParams } from "react-router-dom";
import MyPagination from "../../../../../components/common/myPagination";
import "../Index.css";

const TotalReaction = () => {
  const dispatch = useDispatch();
  const { reactions, loading, error, totalCount } = useSelector(
    (state) => state.reactions || {}
  );
  const navigate = useNavigate();
  const { _id: storyId } = useParams();
  const [paginationDetails, setPaginationDetails] = useState({
    page: 1,
    limit: 20,
  });

  const fetchSingleStoryReactions = async () => {
    const request = { storyId, ...paginationDetails };
    dispatch(SingleStoryReactions(request));
  };

  useEffect(() => {
    fetchSingleStoryReactions();
  }, [dispatch, storyId, paginationDetails]);

  const handlePageChange = (page) => {
    setPaginationDetails((prev) => ({ ...prev, page }));
  };


  return (
    <>
      <NonAuthLayout>
        <div className="header">
          <h2>All Reactions</h2>
          <p>Total Reaction Count{": "}{totalCount}</p>
        </div>
        <div>
        
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.no</th>
                <th>User Name</th>
                <th>User Avatar</th>
                <th>Gender</th>
                <th>Reaction Type</th>
                <th>Created Time</th>
              </tr>
            </thead>
            {Array.isArray(reactions) && reactions.length > 0 ? (
  <tbody>
    {reactions.map((reaction, index) => (
      <tr key={reaction._id}>
        <td>{index + 1}</td>
        <td>{reaction.user?.name || "N/A"}</td>
        <td>
          <img
            src={reaction.user?.avatar?.url || "./../../../../../assets/images/dp.jpg"}
            alt="User Avatar"
            style={{ width: "50px",height:"45px", borderRadius: "50%" }}
          />
        </td>
        <td>{reaction.user?.gender || "N/A"}</td>
        <td>{reaction.reactionType || "N/A"}</td>
        <td>{reaction.createdAt ? format_date(reaction.createdAt) : "N/A"}</td>
      </tr>
    ))}
  </tbody>
) : (
<>
<h4 className="my-5 text-center w-100">Not Data Found</h4>
</>
)}

          </Table>
        </div>
        <MyPagination
          paginationDetails={paginationDetails}
          setPaginationDetails={setPaginationDetails}
          totalPosts={totalCount}
        />
      </NonAuthLayout>
    </>
  );
};

export default TotalReaction;
