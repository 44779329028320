import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const getAllCategory = createAsyncThunk(
  "category/getAllCategory",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "/utility/categories/list"            
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const CreateCategorys = createAsyncThunk(
  "category/CreateCategorys",
  async (categoryData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/admin/category/add",categoryData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const DeleteCategory = createAsyncThunk(
  "category/DeleteCategory",
  async (categoryId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `/admin/category/delete/${categoryId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const UpdateCategory = createAsyncThunk(
  "category/UpdateCategory",
  async ({ _id, ...categoryData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/admin/category/update/`, categoryData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

