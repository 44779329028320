import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Table, Row } from "react-bootstrap";
import { getAllConfigs } from "../../../redux/states/configs/thunk";
import NonAuthLayout from "../../../Layout/NonAuthLayout";
import { useNavigate } from "react-router-dom";
import "./Components/Index.css";
import { MdUpdate } from "react-icons/md";

const ManageConfigs = () => {
  const dispatch = useDispatch();
  const { configs } = useSelector((state) => state.configs);
  const navigate = useNavigate();

  const fetchConfigs = async () => {
    dispatch(getAllConfigs());
  };

  useEffect(() => {
    fetchConfigs();
  }, [dispatch]);

  const handleUpdateConfigs = (_id) => {
    navigate(`/update-queries/${_id}`);
  };

  return (
    <NonAuthLayout>
      <section className="manageStories position-relative py-3">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="filterWrp mb-3 px-lg-5 px-3 d-flex align-items-center justify-content-between gap-10">
                <h2 className="m-0 fw-bold">Manage Configs</h2>
              </div>
            </Col>
            <Col>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Details</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {configs ? (
                    <tr key={configs._id}>
                      <td>
                        <div>
                          {configs.logo?.mainLogo ? (
                            <img
                              src={configs.logo.mainLogo}
                              alt="Main Logo"
                              style={{ width: "100px", height: "auto" }}
                            />
                          ) : (
                            <span>No Main Logo Available</span>
                          )}
                          <br />
                          {configs.logo?.favIcon ? (
                            <img
                              src={configs.logo.favIcon}
                              alt="Fav Icon"
                              style={{ width: "50px", height: "auto" }}
                            />
                          ) : (
                            <span>No Fav Icon Available</span>
                          )}
                        </div>
                      </td>
                      <td>
                        <button
                          className="button-update-configs round-pill"
                          onClick={() => handleUpdateConfigs(configs._id)}
                        >
                          <MdUpdate className="update-icon" />
                        </button>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="2" className="text-center">
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {configs && (
                <>
                  <p>
                    <strong>Last Updated By:</strong>{" "}
                    {configs.lastUpdatedBy || "N/A"}
                  </p>
                  <p>
                    <strong>Created At:</strong>{" "}
                    {configs.createdAt
                      ? new Date(configs.createdAt).toLocaleString()
                      : "N/A"}
                  </p>
                  <p>
                    <strong>Updated At:</strong>{" "}
                    {configs.updatedAt
                      ? new Date(configs.updatedAt).toLocaleString()
                      : "N/A"}
                  </p>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </NonAuthLayout>
  );
};

export default ManageConfigs;
