import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Table, Row, Form } from "react-bootstrap";
import { getAllfaqs, DeleteFaqs } from "../../../redux/states/faqs/thunk";
import NonAuthLayout from "../../../Layout/NonAuthLayout";
import { format_date, parseCamelKey } from "../../../utils/parseKey";
import { useNavigate } from "react-router-dom";
import { Box, Button, Text, useToast,  } from "@chakra-ui/react";
import "./Components/Index.css"
import { confirmAlert } from "react-confirm-alert";
import { MdDelete, MdUpdate } from "react-icons/md";
import { IoIosCreate } from "react-icons/io";

const Managefaqs = () => {
  const dispatch = useDispatch();
  const { faqs, loading, error } = useSelector((state) => state.faqs);
  const navigate = useNavigate();

  const fetchFaqs = async () => {
    dispatch(getAllfaqs());
  };

  useEffect(() => {
    fetchFaqs();
  }, [dispatch]);



  const handleCreatefaqs = () => {
    navigate(`/create-faqs`);
  };

  const handleUpdateFaqs = (_id) => {
    navigate(`/update-faqs/${_id}`);
  };
  const handleReadCms = (_id) => {
    navigate(`/read-faqs/${_id}`);
  };


  const handleDeleteFaqs = (_id) => {
      confirmAlert({
        title: "Confirm Action",
        message: "Are you sure you want to delete this Faqs Page?",
        buttons: [
          {
            label: "Yes",
            onClick: () => 
              dispatch(
                DeleteFaqs(_id),
            ),
          },
          {
            label: "No",
          },
        ],
      });
    };

  return (
   <>
   <NonAuthLayout>
      <section className="manageStories position-relative py-3">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
            <div className="filterWrp mb-3 px-lg-5 px-3 d-flex align-items-center justify-content-between gap-10">
              <h2 className="m-0 fw-bold">Manage Faqs</h2>
              <button
                className="button-create-faqs"
                variant="danger"
                onClick={handleCreatefaqs}
              >
               <IoIosCreate  className="create-icon"/>
              </button>
            </div>
            </Col>

            <Col>
              {faqs?.length > 0 ? (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Question</th>
                      <th>Answer</th>
                      <th>Created Time</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {faqs?.map((faq, idx) => (
                      <tr key={faq._id}>
                        <td>{idx + 1 || "N/A"}</td>
                        <td>{faq?.question || "N/A"}</td>
                        <td> 
                         <Box
                            mt={4}
                            dangerouslySetInnerHTML={{ __html: faq?.answer || "N/A" }}
                            fontSize="md"
                            color="gray.700"
                          /></td>
                        <td>{format_date(faq?.createdAt || "N/A")}</td>
                        <td
                          style={{
                           color: faq.status === "active" ? "rgb(39, 117, 0)" : "rgb(217, 26, 26)" 
                            }}>{faq?.status || "N/A"}</td>
                        <td>
                          <button
                           className="button-update-faqs"
                            variant="danger"
                            onClick={() => handleUpdateFaqs(faq._id)}>
                            <MdUpdate  className="update-icon"/>
                            </button>
                          <button
                           className="button-delete-faqs"
                            variant="danger"
                            onClick={() => handleDeleteFaqs(faq._id)}>
                            <MdDelete  className="delete-icon"/>
                            </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h4 className="my-5 text-center w-100">Not Data Found</h4>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </NonAuthLayout>
</>
  );
};

export default Managefaqs;
