import { createSlice } from "@reduxjs/toolkit";
import { getAllQuries,ReadQuries, UpdateQuriesStatus } from "./thunk";

const initialState = {
  quries: [],
  loading: false,
  error: null,
  totalCount: 0,

};

const quriesSlice = createSlice({
  name: "quries",
  initialState : {
    quries: [],
    loading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    //ya wala slice all getAllQuries list ko get krne k lia tha 
      .addCase(getAllQuries.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllQuries.fulfilled, (state, action) => {
        state.loading = false;
        state.quries = action.payload.data.records || [];
        state.totalCount = action.payload.data.totalCount || 0;
      })
      .addCase(getAllQuries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })  
        //aab sa UpdateQuries ka slice start
        .addCase(UpdateQuriesStatus.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(UpdateQuriesStatus.fulfilled, (state, action) => {
            state.loading = false;
            const updatedFaq = action.payload;
            state.quries = state.quries.map((faq) =>
              faq._id === updatedFaq._id ? updatedFaq : faq
            );
          })
          .addCase(UpdateQuriesStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
  }
});

export const { } = quriesSlice.actions;

export default quriesSlice.reducer;
