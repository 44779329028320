import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const getAllQuries = createAsyncThunk(
  "quries/getAllQuries",
  async ({page, limit, order, orderBy, status, search, reason}, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/admin/queries/list",{
            page,
            limit,
            order: -1,
            orderBy: "updatedAt",
            status: "", //"active", "closed", "resolved", "fullfilled"
            search: "",
            reason: "" //   "suggestionOrFeedback",    "problemOrComplaint",    "reportIllegalOrInappropriateContent",
        }            
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const ReadQuries = createAsyncThunk(
    "quries/ReadQuries",
    async (queryId, { rejectWithValue }) => {
      try {
        const response = await axios.get(`/admin/queries/details/${queryId}`);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
      }
    }
  );


export const UpdateQuriesStatus = createAsyncThunk(
  "quries/UpdateFaqs",
  async ({ queryId, ...queryData }, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/query/update`, {queryId , ...queryData });
        return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

