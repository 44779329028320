import { createSlice } from "@reduxjs/toolkit";
import { getAllConfigs,UpdateConfigs } from "./thunk";

const initialState = {
  configs: [],
  loading: false,
  error: null,
};

const configsSlices = createSlice({
  name: "configs",
  initialState : {
    configs: [],
    loading: false,
    error: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    //ya wala slice getAllConfigs ko get krne k lia tha 
      .addCase(getAllConfigs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllConfigs.fulfilled, (state, action) => {
        state.loading = false;
        state.configs = action.payload;
      })
      .addCase(getAllConfigs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })      
                  //aab sa UpdateConfigs ka slice start
                  .addCase(UpdateConfigs.pending, (state) => {
                    state.loading = true;
                    state.error = null;
                  })
                  .addCase(UpdateConfigs.fulfilled, (state, action) => {
                    state.loading = false;
                    state.configs = state.category.filter(
                      (cate) => cate._id !== action.payload._id
                    );
                  })
                  .addCase(UpdateConfigs.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action.payload;
                  })

  }
});

export const { } = configsSlices.actions;

export default configsSlices.reducer;