import { createSlice } from "@reduxjs/toolkit";
import { getAllStory, SingleStoryDetails,StoryBlockorUnblock } from "./thunk";

const initialState = {
  stories: [],
  loading: false,
  error: null,
  totalCount: 0,
  reactions:[]
};

const storySlice = createSlice({
  name: "stories",
  initialState : {
    stories: [],
    loading: false,
    error: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    //ya wala slice all story ko get krne k lia tha 
      .addCase(getAllStory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllStory.fulfilled, (state, action) => {
        state.loading = false;
        state.stories = action.payload.data.records || 0;
        state.totalCount = action.payload.data.totalCount || 0;
      })
      .addCase(getAllStory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })      
          //ya wala slice Single story ko get krne k lia tha 
         .addCase(SingleStoryDetails.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(SingleStoryDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.stories = action.payload || 0;
          })
          .addCase(SingleStoryDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          }) 
          //aab sa StoryBlockorUnblock ka slice start
                .addCase(StoryBlockorUnblock.pending, (state) => {
                  state.loading = true;
                  state.error = null;
                })
                .addCase(StoryBlockorUnblock.fulfilled, (state, action) => {
                  state.loading = false;
                  state.stories = state.stories.map((story) =>
                    story._id === action.payload._id 
                      ? { ...story, status: action.payload.status }
                      : story
                  );
                })
                .addCase(StoryBlockorUnblock.rejected, (state, action) => {
                  state.loading = false;
                  state.error = action.payload;
                });

  }
});

export const { } = storySlice.actions;

export default storySlice.reducer;
