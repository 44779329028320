import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const getAllfaqs = createAsyncThunk(
  "faqs/getAllfaqs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "/faqs/list"            
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// export const getSinglePage = createAsyncThunk(
//   "faqs/getSinglePage",
//   async (pageId, { rejectWithValue }) => {
//     try {
//       const response = await axios.post(
//         "/utility/cms/page/content",{pageId}          
//       );
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response?.data || error.message);
//     }
//   }
// );

export const CreateFaqsThunk = createAsyncThunk(
  "faqs/CreateFaqs",
  async (faqsData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/admin/faq/add",faqsData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const DeleteFaqs = createAsyncThunk(
  "faqs/DeleteFaqs",
  async (faqId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `/admin/faq/delete/`,{data: { faqId }});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const UpdateFaqs = createAsyncThunk(
  "faqs/UpdateFaqs",
  async ({ faqId, ...faqsData }, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/faq/update/`, { faqId, ...faqsData });
        return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

