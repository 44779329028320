import { createSlice } from "@reduxjs/toolkit";
import { getAllfaqs,CreateFaqsThunk,DeleteFaqs, UpdateFaqs } from "./thunk";

const initialState = {
  faqs: [],
  loading: false,
  error: null,
};

const faqsSlice = createSlice({
  name: "faqs",
  initialState : {
    faqs: [],
    loading: false,
    error: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    //ya wala slice all cms page list ko get krne k lia tha 
      .addCase(getAllfaqs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllfaqs.fulfilled, (state, action) => {
        state.loading = false;
        state.faqs = action.payload.data;
      })
      .addCase(getAllfaqs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })      
    //ya wala slice single cms ko get krne k lia tha 
    //   .addCase(getSinglePage.pending, (state) => {
    //     state.loading = true;
    //     state.error = null;
    //   })
    //   .addCase(getSinglePage.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.faqs = action.payload.data;
    //   })
    //   .addCase(getSinglePage.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload;
    //   })      
        //   ya wala slice CreateFaqs create krne k lia h
         .addCase(CreateFaqsThunk.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(CreateFaqsThunk.fulfilled, (state, action) => {
            state.loading = false;
            state.faqs.push(action.payload);
          })
          .addCase(CreateFaqsThunk.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          }) 
          //aab sa DeleteFaqs ka slice start
                .addCase(DeleteFaqs.pending, (state) => {
                  state.loading = true;
                  state.error = null;
                })
                .addCase(DeleteFaqs.fulfilled, (state, action) => {
                  state.loading = false;
                  state.faqs = state.faqs.filter(
                    (faq) => faq._id !== action.payload._id
                  );
                })
                .addCase(DeleteFaqs.rejected, (state, action) => {
                  state.loading = false;
                  state.error = action.payload;
                })
                  //aab sa UpdateFaqs ka slice start
                  .addCase(UpdateFaqs.pending, (state) => {
                    state.loading = true;
                    state.error = null;
                  })
                  .addCase(UpdateFaqs.fulfilled, (state, action) => {
                    state.loading = false;
                    const updatedFaq = action.payload;
                    state.faqs = state.faqs.map((faq) =>
                      faq._id === updatedFaq._id ? updatedFaq : faq
                    );
                  })
                  .addCase(UpdateFaqs.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action.payload;
                  })

  }
});

export const { } = faqsSlice.actions;

export default faqsSlice.reducer;
