import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { SendNotifications } from "../../../../redux/states/Notification/thunk";
import { useNavigate } from "react-router-dom";
import "./Index.css";
// import blockSubmitRefresh from "./../../../../utils/blockSubmitRefresh"

const CreateNotification = () => {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.notification);
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const notificationData = {
      title,
      body,
    };

    dispatch(SendNotifications(notificationData))
      .unwrap()
      .then(() => {
        // <blockSubmitRefresh />
        setTitle("");
        setBody("");
        setLoading(false); 
      })
      .catch((err) => {
        console.error(err);
        setLoading(false); 
      });
  };

  return (
    <section className="position-relative py-3">
      <div className="row">
          <div className="col-md-12">
            <form onSubmit={handleSubmit} className="p-4 bg-light rounded shadow">
              <div className="mb-3">
                <label htmlFor="notificationTitle" className="form-label">
                  Notification Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cmsTitle"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter title"
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Notification Body</label>
                <textarea
                  className="form-control"
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                  placeholder="Enter notification body"
                  rows="4"
                  required
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="button-create-notification"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Send Notification"
                  )}
                </button>
              </div>
            </form>
            {error && (
              <div className="alert alert-danger mt-3 text-center">
                {error}
              </div>
            )}
          </div>
          </div>
    </section>
  );
};

export default CreateNotification;
