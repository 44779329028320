import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Table, Row, Form } from "react-bootstrap";
import { SingleStoryDetails } from "../../../../redux/states/story/thunk";
import NonAuthLayout from "../../../../Layout/NonAuthLayout";
import { format_date, parseCamelKey } from "../../../../utils/parseKey/index";
import { useNavigate, useParams } from "react-router-dom";
import "./Index.css"
import LoadIndicator from "../../../../components/common/load-indicator";
import { Box, Divider } from "@chakra-ui/react";
import { FaHandPointRight } from "react-icons/fa";


const Readstory = () => {
      const dispatch = useDispatch();
      const { stories, loading, error, totalCount } = useSelector((state) => state.stories);
      const navigate = useNavigate();
      const { _id: storyId } = useParams();
      const [showFullDescription, setShowFullDescription] = useState(false);

      
       const fetchSingleStory = async () => {
        dispatch(SingleStoryDetails(storyId));
      };
      
        useEffect(() => {
            fetchSingleStory();
        }, [dispatch, storyId]);

        const handleTotalReactions = (_id) => {
          navigate(`/read-reactions/${_id}`);
        };

        const handleTotalComments = (_id) => {
          navigate(`/read-comments/${_id}`);
        };
        const handleReadMore = () => {
          setShowFullDescription(true);
        };
      
        const getDescriptionChunks = (description) => {
          const words = description?.split(" ");
          return words;
        };
      
        const descriptionWords = getDescriptionChunks(stories?.description);
        
    return (
      <>
        <NonAuthLayout>
       <div className="row col-12 p-3">
      {stories ? (
          <>
            <h1>Story Details</h1>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Title</td>
                  <td>{stories?.title || "Not Defined"}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{stories?.status || "Not Defined"}</td>
                </tr>
                <tr>
                  <td>Category</td>
                  <td>{stories?.category?.name || "Not Defined"}</td>
                </tr>
                <tr>
                  <td>Special Category</td>
                  <td>{stories?.category?.specialName || "Not Defined"}</td>
                </tr>
                <tr>
                  <td>Icons</td>
                  <td>
                    <Box display="flex">
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: stories?.category?.iconRegular || "Not Loading",
                        }}
                      />
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: stories?.category?.iconFilled || "Not Loading",
                        }}
                      />
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td>Is Private</td>
                  <td>{stories?.isPrivate ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Is Bookmarked</td>
                  <td>{stories?.isBookmarked ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>My Reaction</td>
                  <td>
                    {stories?.myReaction ? (
                      <>{stories?.myReaction}</>
                    ) : (
                      <>|| <><LoadIndicator /></></>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Create Date</td>
                  <td>{format_date(stories?.createdAt) || "Not Defined"}</td>
                </tr>
                <tr>
                  <td>Total Comments</td>
                  <td style={{display:"flex"}}>
                    {stories?.commentsCount ? (
                      <><h3>{stories?.commentsCount}</h3>{<FaHandPointRight className="hand-icon"/> }
                       <button
                      className="button-readallreactions"
                      onClick={() => handleTotalComments(stories._id)}
                    >
                      Read All Comments
                    </button>
                    </>
                    ) : (
                      <>No Comments Yet</>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Total Reactions</td>
                  <td style={{display:"flex"}}><h3>{stories?.reactionsCount || "Not Defined"}</h3>
                   {<FaHandPointRight className="hand-icon"/> }
                   <button
                      className="button-readallcomments"
                      onClick={() => handleTotalReactions(stories._id)}
                    >
                      Read All Reactions
                    </button></td> 
                </tr>
                <tr>
                  <td>Description</td>
                  <td>
                    <Box>
                      <Box>
                        {descriptionWords?.slice(1 , 20).join(" ")}
                      </Box>
                      {descriptionWords?.length > 20 && !showFullDescription && (
                        <button 
                          className="button-readallcomments"
                          // style={{ backgroundColor: "transparent", color: "blue", border: "none", cursor: "pointer" }}
                          onClick={handleReadMore}
                        >
                          Read more
                        </button>
                      )}
                      {showFullDescription && (
                        <Box>
                          {descriptionWords?.join(" ")}
                        </Box>
                      )}
                    </Box>
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          <>
          <h4 className="my-5 text-center w-100">Not Data Found</h4>
          </>       
           )}
       </div>
        </NonAuthLayout>
      </>
    )
}

export default Readstory
