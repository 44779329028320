import { createSlice } from "@reduxjs/toolkit";
import { getAllCategory, CreateCategorys, DeleteCategory,UpdateCategory } from "./thunk";

const initialState = {
  category: [],
  loading: false,
  error: null,
};

const categorySlice = createSlice({
  name: "category",
  initialState : {
    category: [],
    loading: false,
    error: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    //ya wala slice all story ko get krne k lia tha 
      .addCase(getAllCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.category = action.payload.data;
      })
      .addCase(getAllCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })      
        //   ya wala slice category create krne k lia h
         .addCase(CreateCategorys.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(CreateCategorys.fulfilled, (state, action) => {
            state.loading = false;
            state.category.push(action.payload);
          })
          .addCase(CreateCategorys.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          }) 
          //aab sa DeleteCategory ka slice start
                .addCase(DeleteCategory.pending, (state) => {
                  state.loading = true;
                  state.error = null;
                })
                .addCase(DeleteCategory.fulfilled, (state, action) => {
                  state.loading = false;
                  state.category = state.category.filter(
                    (cate) => cate._id !== action.payload._id
                  );
                })
                .addCase(DeleteCategory.rejected, (state, action) => {
                  state.loading = false;
                  state.error = action.payload;
                })
                  //aab sa UpdateCategory ka slice start
                  .addCase(UpdateCategory.pending, (state) => {
                    state.loading = true;
                    state.error = null;
                  })
                  .addCase(UpdateCategory.fulfilled, (state, action) => {
                    state.loading = false;
                    state.category = state.category.filter(
                      (cate) => cate._id !== action.payload._id
                    );
                  })
                  .addCase(UpdateCategory.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action.payload;
                  })

  }
});

export const { } = categorySlice.actions;

export default categorySlice.reducer;
