import { createSlice } from "@reduxjs/toolkit";
import { SendNotifications } from "./thunk";

const initialState = {
  notification: [],
  loading: false,
  error: null,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState : {
    notification: [],
    loading: false,
    error: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder   
        //   ya wala slice cms create krne k lia h
         .addCase(SendNotifications.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(SendNotifications.fulfilled, (state, action) => {
            state.loading = false;
            notification.cms.push(action.payload);
          })
          .addCase(SendNotifications.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          }) 
  }
});

export const { } = notificationSlice.actions;

export default notificationSlice.reducer;
