import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuthData } from "./../../../components/common/authUtils"


export const getAllStory = createAsyncThunk(
  "stories/getAllstory",
  async ({ page, limit, order, orderBy, listType, status, category, isPrivate, anonymousSharing, searchTerm }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/stories/list",
        {
          page,
          limit,
          order: -1,
          orderBy: "createdAt",
          listType: "admin",
          status,  // active | blocked | deleted
          category,  //category Id
          isPrivate,  //true | false
          anonymousSharing, // true | false
          searchTerm, // Search term for filtering stories
        }
            );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


export const SingleStoryDetails = createAsyncThunk(
  "stories/SingleStoryDetails",
  async (storyId ,{ rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/story/details/",
        { storyId }
      );
      return response.data.data;
     } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const SingleStoryReactions = createAsyncThunk(
  "stories/SingleStoryDetails",
  async ({ storyId, page, limit } ,{ rejectWithValue }) => {
    try {
      const {data} = await axios.post(
        "story/reaction/list",
        { 
        page,
        limit,
        order: 1,
        orderBy: "createdAt",
        storyId
         }
              );
      return data.data;
     } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const SingleStoryComments = createAsyncThunk(
  "stories/SingleStoryComments",
  async ({ storyId, page, limit } ,{ rejectWithValue }) => {
    try {
      const response = await axios.post(
        "story/comments/list",
        { 
        page,
        limit,
        order: 1,
        orderBy: "createdAt",
        storyId
         }      );
      return response.data; 
     } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const StoryBlockorUnblock = createAsyncThunk(
  "users/StoryBlockorUnblock",
  async ({  storyId, currentStatus, title, description,categoryId  }, { rejectWithValue }) => {
    try {
      const action = currentStatus === "active" ? "blocked" : "active";
      const body = {
        storyId,
        status: action,
        title,
        description,
        category:categoryId,
      };
      const { data } = await axios.post(`/story/update`, body);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
