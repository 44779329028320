import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const getAllUsers = createAsyncThunk(
  "users/getAll",
  async ({ page, limit, order, orderBy, gender, status, search }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/admin/users/list",
        {
          page,
          limit,
          order:-1,
          orderBy:"createdAt",
          gender:"",
          status:"",
          search:"",
        }
            );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const SingleUserProfile = createAsyncThunk(
  "users/SingleUserGet",
  async (otherUserId ,{ rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/profile/others",
        { otherUserId }
      );
      return response.data.data;
     } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const SingleUserStory = createAsyncThunk(
  "users/SingleUserStory",
  async ({ otherUserId, page = 1, limit = 20 }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/stories/list",
        {
          page,
          limit,
          order: "-1",
          orderBy: "createdAt",
          listType: "others",
          user: otherUserId,
        } 
       );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const UserStoryReports = createAsyncThunk(
  "users/userStoryReports",
  async ({ otherUserId, page, limit }, { rejectWithValue }) => {
    try {
      const body = {
        page,
        limit,
        order: "-1",
        orderBy: "createdAt",
        listType: "others",
        user: otherUserId,
      };
      const { data } = await axios.post(`/admin/user/reports/list`, body);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


export const UserBlockorUnblock = createAsyncThunk(
  "users/userBlockorUnblock",
  async ({  targetUserId, currentStatus  }, { rejectWithValue }) => {
    try {
      const action = currentStatus === "active" ? "blocked" : "active";
      const body = {
        otherUserId: targetUserId,
        status: action,
      };
      const { data } = await axios.post(`/admin/user/status/update`, body);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
