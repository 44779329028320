import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./states/auth/slice";
import dashboardSlice from "./states/dashboard/slice";
import commonSlice from "./states/common/slice";
import userSlice from "./states/user/slice";
import storySlice from "./states/story/slice";
import storyReactionSlice from "./states/story/reactionSlice";
import storyCommentSlice from "./states/story/commentSlice";
import categorySlice from "./states/Category/slice";
import cmsSlice from "./states/cms/slice";
import singlecmsSlice from "./states/cms/SingleSlice";
import faqsSlice from "./states/faqs/slice";
import quriesSlice from "./states/Quries/slice";
import singleSliceView from "./states/Quries/singleSlice";
import notificationSlice from "./states/Notification/slice";

const rootReducer = combineReducers({
  auth: authReducer,
  common: commonSlice,
  dashboard: dashboardSlice,
  users: userSlice,
  stories: storySlice,
  reactions: storyReactionSlice,
  comments: storyCommentSlice,
  category: categorySlice,
  cms: cmsSlice,
  cmsSingle: singlecmsSlice,
  faqs: faqsSlice,
  quries:quriesSlice,
  quriessingle:singleSliceView,
  notification:notificationSlice
});

export default rootReducer;
