import { Routes, Route, Navigate,useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLayoutEffect, useEffect, useState } from "react";
import publicRoutes from "./pages/publicRoutes";
import runAxiosSetup from "./helper/axiosSetup";
import { baseApiUrl } from "./config";
import Dashboard from "./pages/SideTabs/Dashboard/Index";
import ManageUsers from "./pages/SideTabs/ManageUsers/Index";
import ViewUser from "./pages/SideTabs/ManageUsers/components/ViewUser";
import ManageStory from "./pages/SideTabs/ManageStory/Index.jsx";
import Readstory from "./pages/SideTabs/ManageStory/Components/Readstory";
import TotalComments from "./pages/SideTabs/ManageStory/Components/SubComponents/TotalComments.js";
import TotalReaction from "./pages/SideTabs/ManageStory/Components/SubComponents/TotalReaction.js";
import ManageCategory from "./pages/SideTabs/ManageCategory/Index.jsx";
import CreateCategory from "./pages/SideTabs/ManageCategory/Component/CreateCategory.js";
import ManageCms from "./pages/SideTabs/ManageCms/Index.jsx";
import CreateCms from "./pages/SideTabs/ManageCms/Components/CreateCms.js";
import ReadCmsPage from "./pages/SideTabs/ManageCms/Components/ReadCmsPage.js";
import Managefaqs from "./pages/SideTabs/ManageFaqs/Index.jsx";
import CreateFaqs from "./pages/SideTabs/ManageFaqs/Components/CreateFaqs.js";
import ManageQueris from "./pages/SideTabs/ManageQuery/Index.jsx";
import UpdateQuries from "./pages/SideTabs/ManageQuery/Components/QueryUpdate.js";
import ViewQueries from "./pages/SideTabs/ManageQuery/Components/ViewQueries.js";
import ManageNotification from "./pages/SideTabs/ManageNotification/Index.jsx";
import ManageConfigs from "./pages/SideTabs/ManageConfigs/Index.jsx";



function App() {
  const {admin, isAuthenticated } = useSelector((state) => state.auth || {});
  const [privateRoutes, setPrivateRoutes] = useState([]);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    runAxiosSetup({
      apiUrl: baseApiUrl,
      bearerToken: admin?.data?.token,
      headers: {
        Authorization: admin?.data?.token || "",
        userId:admin?.data?.userId || "",
      },
    });
  }, [admin, isAuthenticated]);


  useEffect(() => {
    if (isAuthenticated) {
      setPrivateRoutes([
        { path: "/dashboard", component: <Dashboard /> },
        { path: "/manage-users", component: <ManageUsers /> },
        { path:"/singleuser/:_id", component: <ViewUser /> },
        { path:"/manage-story", component: <ManageStory /> },
        { path:"/read-story/:_id", component: <Readstory /> },
        { path:"/read-comments/:_id", component: <TotalComments /> },
        { path:"/read-reactions/:_id", component: <TotalReaction /> },
        { path:"/manage-category", component: <ManageCategory /> },
        { path:"/create-category", component: <CreateCategory /> },
        { path:"/update-category/:id", component: <CreateCategory /> },
        { path:"/manage-csm", component: <ManageCms /> },
        { path:"/create-cms", component: <CreateCms /> },
        { path:"/update-cms/:id", component: <CreateCms /> },
        { path:"/read-cms/:id", component: <ReadCmsPage /> },
        { path:"/manage-faqs", component: <Managefaqs /> },
        { path:"/create-faqs", component: <CreateFaqs /> },
        { path:"/update-faqs/:id", component: <CreateFaqs /> },
        { path:"/manage-quries", component: <ManageQueris /> },
        { path:"/update-quries/:id", component: <UpdateQuries /> },
        { path:"/view-quries/:id", component: <ViewQueries /> },
        { path:"/manage-notificaions", component: <ManageNotification /> },
        { path:"/manage-configs", component: <ManageConfigs /> },
      ]);
    } else {
      setPrivateRoutes([]);
    }
  }, [isAuthenticated, navigate]);

  // console.log(isAuthenticated,"isAuthenticated");
  // console.log(privateRoutes,"privateRoutes");


  return (
    <Routes>
      {isAuthenticated ? (
        <>
          {privateRoutes.map((data, index) => (
            <Route path={data.path} element={data.component} key={index} />
          ))}
          <Route path="*" element={<Navigate replace to="/dashboard" />} />
        </>
      ) : (
        <>
          {publicRoutes.map((data, index) => (
            <Route path={data.path} element={data.component} key={index} />
          ))}
          <Route path="*" element={<Navigate replace to="/" />} />
        </>
      )}
    </Routes>
  );
}

export default App;
