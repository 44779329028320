import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Table, Row, Form } from "react-bootstrap";
import { getAllQuries } from "../../../redux/states/Quries/thunk";
import NonAuthLayout from "../../../Layout/NonAuthLayout";
import { format_date, parseCamelKey } from "../../../utils/parseKey";
import { useNavigate } from "react-router-dom";
import { Box, Button, Text, useToast,  } from "@chakra-ui/react";
import "./Components/Index.css"
import MyPagination from "../../../components/common/myPagination";
import { FaRegEye } from "react-icons/fa";
import { MdUpdate } from "react-icons/md";


const ManageQueris = () => {
  const dispatch = useDispatch();
  const { quries, loading, error,totalCount } = useSelector((state) => state.quries);
  const navigate = useNavigate();
  const [paginationDetails, setPaginationDetails] = useState({ page: 1, limit: 20 });
    const [filters, setFilters] = useState({
      status: "",
      search: "",
      reason: "",
    });
    

  const fetchQuries = async () => {
    const request = { ...paginationDetails, ...filters };
    dispatch(getAllQuries(request));
  };

  useEffect(() => {
    fetchQuries();
  }, [dispatch, filters, paginationDetails]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
    setPaginationDetails((prev) => ({
      ...prev,
      page: 1,
    }));
  };



  const handleUpdatequries = (_id) => {
    navigate(`/update-quries/${_id}`);
  };
  const handleReadReply = (_id) => {
    navigate(`/view-quries/${_id}`);
  };

  return (
   <>
   <NonAuthLayout>
      <section className="manageStories position-relative py-3">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
            <div className="filterWrp mb-3 px-lg-5 px-3 d-flex align-items-center justify-content-between gap-10">
              <h2 className="m-0 fw-bold">Manage Quries</h2>
            </div>
            </Col>

            <Col>
  {quries?.length > 0 ? (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>S.No</th>
          <th>Name</th>
          <th>Mobile</th>
          <th>Email</th>
          <th>Reason</th>
          <th>Comment</th>
          <th>Status</th>
          <th>Replies</th>
          <th>Read Reply</th>
          <th>Create Time</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {quries?.map((qurie, idx) => (
          <tr key={qurie._id}>
            <td>{idx + 1 || "N/A"}</td>
            <td>{qurie?.name || "N/A"}</td>
            <td>{qurie?.mobile || "N/A"}</td>
            <td>{qurie?.email || "N/A"}</td>
            <td>{qurie?.reason || "N/A"}</td>
            <td>{qurie?.comment || "N/A"}</td>
            <td
              style={{
                color: qurie.status === "active" ? "rgb(39, 117, 0)" : "rgb(217, 26, 26)",
              }}
            >
              {qurie?.status || "N/A"}
            </td>
            <td>
              {qurie?.replies?.length > 0
                ? qurie.replies.map((reply, i) => (
                    <Box key={reply._id} fontSize="sm" color="gray.700">
                      Reply {i + 1}: {reply._id || "N/A"}
                    </Box>
                  ))
                : "No replies"}
            </td>
            <td>
            <button
                className="button-read"
                onClick={() => handleReadReply(qurie._id)}
              >
                  <FaRegEye  className="eye-icon"/>
              </button>
            </td>
            <td>{format_date(qurie?.createdAt) || "N/A"}</td>
            <td>
              <button
                className="button-update"
                onClick={() => handleUpdatequries(qurie._id)}
              >
                 <MdUpdate  className="update-icon"/>
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <h4 className="my-5 text-center w-100">No Data Found</h4>
  )}
</Col>

          </Row>
        </Container>
        <MyPagination
                paginationDetails={paginationDetails}
                setPaginationDetails={setPaginationDetails}
                totalPosts={totalCount}
              />
      </section>
    </NonAuthLayout>
</>
  );
};

export default ManageQueris;