import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Table, Row, Form } from "react-bootstrap";
import { getAllStory, StoryBlockorUnblock } from "../../../redux/states/story/thunk";
import NonAuthLayout from "../../../Layout/NonAuthLayout";
import { format_date, parseCamelKey } from "../../../utils/parseKey";
import { useNavigate } from "react-router-dom";
import MyPagination from "../../../components/common/myPagination";
// import MyConfirmModal from "../../../components/Modals/ConfirmModal";
import { Box, Button, Text, useToast,  } from "@chakra-ui/react";
import "./Components/Index.css"
import { confirmAlert } from "react-confirm-alert";
import { FaRegEye } from "react-icons/fa";

const ManageStory = () => {
  const dispatch = useDispatch();
  const { stories, loading, error, totalCount } = useSelector((state) => state.stories);
  const navigate = useNavigate();
  const [paginationDetails, setPaginationDetails] = useState({ page: 1, limit: 20 });

  

  const fetchStory = async () => {
    const request = { ...paginationDetails };
    dispatch(getAllStory(request));
  };

  useEffect(() => {
    fetchStory();
  }, [paginationDetails]);


  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setPaginationDetails((prev) => ({
      ...prev,
      page: 1,
    }));
  };



  const handleReadStory = (_id) => {
    navigate(`/read-story/${_id}`);
  };

  const handleBlockUnblock = (storyId, currentStatus, title, description, categoryId) => {
      confirmAlert({
        title: "Confirm Action",
        message: `Are you sure you want to ${currentStatus === "active" ? "block" : "unblock"} this user?`,
        buttons: [
          {
            label: "Yes",
            onClick: () => dispatch(
              StoryBlockorUnblock({
                storyId: storyId, 
                currentStatus: currentStatus,
                title: title,
                description:description,
                categoryId:categoryId,
              },
             ),
            ),
          },
          {
            label: "No",
          },
        ],
      });
    };
  
  

  return (
   <>
   <NonAuthLayout>
      <section className="manageStories position-relative py-3">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="filterWrp mb-3 px-lg-5 px-3 d-flex align-items-center flex-wrap justify-content-between gap-10">
                <div>
                  <h2 className="m-0 fw-bold">Manage Story</h2>
                  <h6 className="muted">
                    Total Story Count {": "} {totalCount || "Total Story loading "}
                  </h6>
                </div>
              </div>
            </Col>

            <Col>
              {stories?.length > 0 ? (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Title</th>
                      <th>Category</th>
                      <th>Special Name</th>
                      <th>Is Primary</th>
                      <th>Category Icon</th>
                      <th>Is Private</th>
                      <th>Status</th>
                      <th>Posted by</th>
                      <th>Posted by (Avatar)</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th>Reactions</th>
                      <th>Views</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stories?.map((stry, idx) => (
                      <tr key={stry._id}>
                        <td>{idx + 1 || "N/A"}</td>
                        <td>{stry?.title || "N/A"}</td>
                        <td>{stry?.category?.name || "N/A"}</td>
                        <td>{stry?.category?.specialName || "N/A"}</td>
                        <td>{stry?.category?.isPrimary ? "Yes" : "No" || "N/A"}</td>
                        <td>
                          <Box display="flex">
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: stry.category?.iconFilled || "",
                              }}
                            />
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: stry.category?.iconRegular || "",
                              }}
                            />
                          </Box>
                        </td>
                        <td>{stry?.isPrivate ? "Yes" : "No"}</td>
                        <td>{stry?.status || "N/A"}</td>
                        <td>{stry?.user?.name || "Anonymous"}</td>
                        <td>
                          <img 
                            src={stry?.user?.avatar?.url || "./../../../assets/images/dp.jpg"}
                            alt="User Avatar"
                            className="userprofile"
                          />
                        </td>
                        <td>{format_date(stry?.createdAt) || "N/A"}</td>
                        <td>{format_date(stry?.updatedAt) || "N/A"}</td>
                        <td>{stry?.reactionsCount || 0}</td>
                        <td>{stry?.viewsCount || 0}</td>
                        <td>
                          <button
                            variant="primary"
                            className="button-readstory"
                            onClick={() => handleReadStory(stry._id)}
                          >
                             <FaRegEye className="eye-icon" />
                          </button>
                          <button
                           className="button-blovkstory"
                           style={{
                           backgroundColor: stry.status === "active" ? "rgb(220, 73, 73)" : "rgb(6, 184, 6)" 
                            }}
                            variant="danger"
                            onClick={() => handleBlockUnblock(stry._id, stry.status, stry.title,stry.description, stry.category._id)}>
                            {stry.status === "active" ? "Block" : "Unblock"}
                            </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h4 className="my-5 text-center w-100">Not Data Found</h4>
              )}
            </Col>

            <MyPagination
              paginationDetails={paginationDetails}
              setPaginationDetails={setPaginationDetails}
              totalPosts={totalCount}
            />
          </Row>
        </Container>
      </section>
    </NonAuthLayout>

</>
  );
};

export default ManageStory;
