import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Table, Row, Form, Accordion } from "react-bootstrap";
import { SingleStoryComments } from "../../../../../redux/states/story/thunk";
import NonAuthLayout from "../../../../../Layout/NonAuthLayout";
import { format_date } from "../../../../../utils/parseKey/index";
import { useNavigate, useParams } from "react-router-dom";
import ScrollToTop from "../../../../../ScrollToTop";
import MyPagination from "../../../../../components/common/myPagination";
import LoadIndicator from "../../../../../components/common/load-indicator";
import "../Index.css";
import { Divider } from "@chakra-ui/react";

const TotalComments = () => {
  const dispatch = useDispatch();
  const { comments, loading, error, totalCount } = useSelector(
    (state) => state.comments
  );
  const navigate = useNavigate();
  const { _id: storyId } = useParams();
  const [paginationDetails, setPaginationDetails] = useState({
    page: 1,
    limit: 20,
  });
  console.log(comments,"data");
  console.log(totalCount,"total count");

useEffect(() => {
    const fetchSingleStoryComments = async () => {
      const request = { storyId, ...paginationDetails };
      dispatch(SingleStoryComments(request));
    };
    fetchSingleStoryComments();
  }, [dispatch, storyId, paginationDetails]);


  const handlePageChange = (page) => {
    setPaginationDetails((prev) => ({ ...prev, page }));
  };

//   if (loading) return <><LoadIndicator /></>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
   <NonAuthLayout>
        <div className="header">
          <h2>All Comments</h2>
          <Divider color="black" />
          <p>
            Total Commets Count: {totalCount}
          </p>
        </div>
        <div style={{padding:"10px"}}>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>S.no</th>
                <th>User Name</th>
                <th>User Avatar</th>
                <th>Gender</th>
                <th>Comment</th>
                <th>My Reaction</th>
                <th>Created Time</th>
                <th>Replies Count</th>
                <th>Likes Count</th>
              </tr>
            </thead>
            {Array.isArray(comments) && comments.length > 0 ? (
              <tbody>
                {comments.map((comment, index) => (
                  <React.Fragment key={comment._id}>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{comment.user?.name || "N/A"}</td>
                      <td>
                        <img
                          src={
                            comment.user?.avatar?.url ||
                            "./../../../../../assets/images/dp.jpg"
                          }
                          alt="User Avatar"
                          style={{ width: "50px", height: "45px", borderRadius: "50%" }}
                        />
                      </td>
                      <td>{comment.user?.gender || "N/A"}</td>
                      <td>{comment.comment || "N/A"}</td>
                      <td>{comment.reactionType || "N/A"}</td>
                      <td>{comment.createdAt ? format_date(comment.createdAt) : "N/A"}</td>
                      <td>{comment.repliesCount || "N/A"}</td>
                      <td>{comment.likesCount || "N/A"}</td>
                    </tr>
                    <tr>
                      <td colSpan="12">
                        <Accordion >
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Replies ({comment.replies.length})</Accordion.Header>
                            <Accordion.Body>
                              {comment.replies.length > 0 ? (
                                <ul>
                                  {comment.replies.map((reply) => (
                                    <li key={reply._id}>
                                    <img src={reply.user?.avatar?.url ||"./../../../../../assets/images/dp.jpg"}
                                      alt="User Avatar"
                                     style={{ width: "50px", height: "45px", borderRadius: "50%", margin:"3px" }}
                                      /> 
                                      <strong>{reply.user?.name || "N/A"}:</strong> {reply.reply}
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <p>No replies available.</p>
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            ) : (
              <>
              <h4 className="my-5 text-center w-100">Not Data Found</h4>
              </>
            )}
          </Table>
        </div>
        <MyPagination
          paginationDetails={paginationDetails}
          setPaginationDetails={setPaginationDetails}
          totalPosts={totalCount}
        />
      </NonAuthLayout>

    </>
  );
};

export default TotalComments;
