import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Table, Row, Form } from "react-bootstrap";
import { ReadQuries } from "../../../../redux/states/Quries/thunk";
import NonAuthLayout from "../../../../Layout/NonAuthLayout";
import { format_date, parseCamelKey } from "../../../../utils/parseKey/index";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Text, useToast,  } from "@chakra-ui/react";
import "./Index.css"

const ViewQueries = () => {
    const dispatch = useDispatch();
    const { quriessingle, loading, error } = useSelector((state) => state.quriessingle);
    const navigate = useNavigate();
    const { id: queryId } = useParams();
    
      console.log(quriessingle,"quriessingle");

      const fetchQuries = async () => {
        if (queryId) {
          dispatch(ReadQuries(queryId));
        } else {
          console.error("Query ID is undefined.");
        }
      };
    
      useEffect(() => {
        fetchQuries();
      }, [dispatch]);

    return (
        <>
        <NonAuthLayout>
          <div>
            <h2>read quries</h2>
            </div>
        </NonAuthLayout>
      </>
    )
}

export default ViewQueries

