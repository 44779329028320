import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { handleApiRequest } from "../../../services/handleApiRequest";
import { errorMsg, successMsg } from "../../../services/toastMessage";
import { setPassword } from "../../../redux/states/auth/thunk";

const SetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const [details, setDetails] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!details.password?.trim()) return errorMsg("Password is required");
    if (!details.confirmPassword?.trim()) return errorMsg("Confirm Password is required");
    if (details.password !== details.confirmPassword)
      return errorMsg("Password and Confirm password does not match");

    const request = { password: details.password, token: token };
    const response = await handleApiRequest(setPassword, request);
    if (response.status) {
      successMsg("Password set sucess");
      navigate("/login");
    }
  };

  return (
    <>
      <div className="w-100 h-100 bg-white d-flex align-items-center justify-content-center">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <Link to="/cars" className="logo d-flex align-items-center w-auto">
                    <img src={"/assets/images/mainLogo.png"} alt="" style={{ maxHeight: 100 }} />
                  </Link>
                </div>
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="text-center pb-0 fs-4 fw-bold">Set Password</h5>
                    </div>

                    <form onSubmit={handleSubmit} className="row g-3 needs-validation">
                      <div className="col-12">
                        <div className="input-group has-validation">
                          <input
                            type="password"
                            name="password"
                            value={details.password || ""}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter new password"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-group has-validation">
                          <input
                            type="password"
                            name="confirmPassword"
                            value={details.confirmPassword || ""}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter new password again               "
                          />
                        </div>
                      </div>

                      <div className="col-12 d-flex justify-content-between">
                        <Button type="submit" variant="danger" className="btn w-100">
                          {"Set Password"}
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SetPassword;
