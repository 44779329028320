import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Table, Row, Form } from "react-bootstrap";
import { getAllQuries } from "../../../redux/states/Quries/thunk";
import NonAuthLayout from "../../../Layout/NonAuthLayout";
import { format_date, parseCamelKey } from "../../../utils/parseKey";
import { useNavigate } from "react-router-dom";
import { Box, Button, Text, useToast,  } from "@chakra-ui/react";
import "./Components/Index.css"


const ManageNotification = () => {
  const dispatch = useDispatch();
  const { quries } = useSelector((state) => state.quries);
  const navigate = useNavigate();


  return (
   <>
   <NonAuthLayout>
      <section className="manageStories position-relative py-3">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
            <div className="filterWrp mb-3 px-lg-5 px-3 d-flex align-items-center justify-content-between gap-10">
              <h2 className="m-0 fw-bold">Manage Notications</h2>
            </div>
            </Col>


          </Row>
        </Container>
      </section>
    </NonAuthLayout>
</>
  );
};

export default ManageNotification;