import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getUserAnalytics = createAsyncThunk(
  "common/getUserAnalytics",
  async (filter, thunk) => {
    try {
      const response = await axios.post(`getUserAnalytics`, filter);
      return response?.data;
    } catch (error) {
      return thunk.rejectWithValue(error);
    }
  }
);

