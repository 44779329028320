import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSinglePage } from "../../../../redux/states/cms/thunk";
import NonAuthLayout from "../../../../Layout/NonAuthLayout";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Box, Text } from "@chakra-ui/react";

const ReadCmsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: pageId } = useParams();
  const { cmsSingle, loading, error } = useSelector((state) => state.cmsSingle);

  const fetchSingleCmsPage = async () => {
    dispatch(getSinglePage(pageId));
  };

  useEffect(() => {
    fetchSingleCmsPage();
  }, [dispatch, pageId]);

  return (
    <>
      <NonAuthLayout>
        <Container fluid className="py-5 bg-light">
          <Row className="justify-content-center">
            <Col md={10} lg={12} >
              {loading && (
                <div className="text-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
              {error && (
                <div className="text-center text-danger">
                  <p>Error: {error}</p>
                </div>
              )}
              {cmsSingle ? (
                <Box
                  bg="white"
                  boxShadow="lg"
                  borderRadius="md"
                  p={10}
                  overflow="hidden"
                  mb={3}

                >
                  <h2 className="text-center text-primary">{cmsSingle.title}</h2>

                  <Row className="mt-4">
                    <Col>
                      <Text fontSize="lg" color="gray.600">
                        <strong>Status: </strong>
                        <span
                          style={{
                            color: cmsSingle.status === "active" ? "green" : "red",
                          }}
                        >
                          {cmsSingle.status}
                        </span>
                      </Text>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col>
                      <Text fontSize="sm" color="gray.500">
                        <strong>Created At: </strong>
                        {new Date(cmsSingle.createdAt).toLocaleString()}
                      </Text>
                    </Col>
                    <Col>
                      <Text fontSize="sm" color="gray.500">
                        <strong>Updated At: </strong>
                        {new Date(cmsSingle.updatedAt).toLocaleString()}
                      </Text>
                    </Col>
                  </Row>

                  <Box
                    mt={4}
                    dangerouslySetInnerHTML={{ __html: cmsSingle.content }}
                    fontSize="md"
                    color="gray.700"
                  />
                </Box>
              ) : (
                <div className="text-center">
                  <p>No CMS page found.</p>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </NonAuthLayout>
    </>
  );
};

export default ReadCmsPage;
