import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const getAllCmsPage = createAsyncThunk(
  "cms/getAllCmsPage",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "/utility/cms/page/list"            
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getSinglePage = createAsyncThunk(
  "cmssingle/getSinglePage",
  async (pageId, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/utility/cms/page/content",{pageId}          
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const CreateCmsPage = createAsyncThunk(
  "cms/CreateCmsPage",
  async (cmsData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/admin/cms/add",cmsData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const DeleteCmsPage = createAsyncThunk(
  "cms/DeleteCmsPage",
  async (pageId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `/admin/cms/delete/`,{data: { pageId }});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const UpdateCmsPage = createAsyncThunk(
  "cms/UpdateCmsPage",
  async ({ pageId, ...categoryData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/admin/cms/update`, categoryData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

