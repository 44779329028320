import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { ReactComponent as OpenEye } from "../../../assets/icons/openEye.svg";
import { ReactComponent as CloseEye } from "../../../assets/icons/openEye.svg";
import { handleApiRequest } from "../../../services/handleApiRequest";
import { staffLogin } from "../../../redux/states/auth/thunk";

const Login = () => {
  const navigate = useNavigate();
  const { rememberedUser } = useSelector((state) => state.auth ?? {});
  const [userCreds, setUserCreds] = useState({});
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setUserCreds((prev) => {
      return {
        ...prev,
        [e.target.name]: value,
      };
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!userCreds.email) return setErrors({ email: "Please enter email" });
    if (!userCreds.password) return setErrors({ password: "Please enter Password" });
    try {
      const response = await handleApiRequest(staffLogin, userCreds); 
      localStorage.setItem("auth", JSON.stringify(response)); 
      if (response) {
        console.log("Login successful, navigating to dashboard...");
        navigate("/dashboard");
      } else {
        console.error("Login failed");
        setErrors({ general: response?.message || "Invalid credentials, please try again" });
      }
    } catch (error) {
      console.error("Error during login:", error);
      setErrors({ general: error.message || "Something went wrong. Please try again." });
    }
  };

  useEffect(() => {
    if (rememberedUser?.email) {
      setUserCreds({ ...rememberedUser, remember: false });
    }
  }, [rememberedUser]);

  return (
    <>
      <div
        className="w-100 h-100 bg-white d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <section className="section register py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <Link to="/cars" className="logo d-flex align-items-center w-auto">
                    <img src={"/assets/images/logo-removebg-preview.png"} alt="" style={{ maxHeight: 100 }} />
                  </Link>
                </div>

                <div className="card mb-3">
                  <div className="card-body">
                    {/* <div className="pt-4 pb-2">
                      <h5 className="text-center pb-0 fs-4 fw-bold">Login</h5>
                      <p className="text-center small">
                        <span className="darkColor">New to Autotitanic? </span>
                        <Link to="/register" className="text-danger">
                          Create Account
                        </Link>
                      </p>
                    </div> */}

                    <form onSubmit={handleLogin} className="row g-3 needs-validation m-0">
                      <>
                        <div className="col-12">
                          <label for="yourUsername" className="form-label mb-0">
                            Email Address <b style={{color:"red"}}>*</b>
                          </label>
                          <div className="input-group has-validation">
                            {/* <span className="input-group-text bg-dark" id="inputGroupPrepend">
                              <i className="bi bi-envelope text-white" />
                            </span> */}
                            <input
                              type="email"
                              name="email"
                              value={userCreds.email || ""}
                              onChange={handleChange}
                              className="form-control"
                              placeholder="Email Address"
                            />
                          </div>
                          {errors.email && <p className="errorMsg">*{errors.email}</p>}
                        </div>
                      </>

                      <>
                        <div className="col-12">
                          <label for="yourPassword" className="form-label mb-0">
                            Password  <b style={{color:"red"}}>*</b>
                          </label>
                          <div className="input-group has-validation iconWithText">
                            <input
                              type={showPassword ? "text" : "password"}
                              name="password"
                              value={userCreds.password || ""}
                              onChange={handleChange}
                              className="form-control"
                              id="yourPassword"
                              placeholder="Password"
                            />
                            <span
                              className="eyeIcon icn position-absolute pointer"
                              onClick={() => setShowPassword((prev) => !prev)}
                            >
                              {showPassword ? <CloseEye /> : <OpenEye />}
                            </span>
                          </div>
                          {errors.password && <p className="errorMsg">*{errors.password}</p>}
                        </div>
                        <div className="col-12">
                          <div className="form-check">
                             <input
                              className="form-check-input"
                              type="checkbox"
                              name="remember"
                              value={userCreds.remember}
                              onChange={handleChange}
                              id="rememberMe"
                            />
                              <label className="form-check-label" htmlFor="rememberMe">
                            Remember me
                          </label>
                          </div>
                        </div>
                      </>

                      <div className="col-12">
                        <Button variant="danger" type="submit" className="btn w-100 ">
                          Login
                        </Button>
                      </div>
                      <div className="col-12 d-flex justify-content-between">
                        <p className="small mb-0">
                          Forgot Password?{" "}
                          <span
                            onClick={() => {
                              navigate("/resetPassword");
                            }}
                            className="pointer theme-clr"
                          >
                            Reset Here
                          </span>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
