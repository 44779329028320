import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import storyImage from "../../../../assets/images/storyimage.png";
import { getAllUsers } from "../../../../redux/states/user/thunk";
import { getAllStory } from "../../../../redux/states/story/thunk";



const CounterCards = () => {
    const dispatch = useDispatch();
    // const { totalCount: userTotalCount } = useSelector((state) => state.users);
    // const { totalCount: storyTotalCount } = useSelector((state) => state.stories);

  return (
    <>
      <Row className="counterCards">
        <Col lg="3" sm="6" className="my-2">
          <div className="CstmCard px-3 py-2 d-flex align-items-center gap-10 justify-content-between h-100">
            <div className="imgWrp">
              <img src="/assets/images/c1.png" alt="" className="img-fluid" />
            </div>
            <div className="content">
              <h6 className="m-0">Welcome Back!</h6>
              <p className="m-0">Felinghub</p>
            </div>
          </div>
        </Col>
        <Col lg="3" sm="6" className="my-2">
          <div className="CstmCard px-3 py-2 d-flex align-items-center gap-10 justify-content-between h-100">
            <div className="content">
              <p className="m-0 theme-blue">Total Users</p>
              <p className="m-0 theme-blue">25, 000 Dummy</p>
              {/* <h6 className="m-0 theme-blue fw-bold">{userTotalCount}</h6> */}
            </div>
            <div className="imgWrp">
              <img src="/assets/images/c2.png" alt="" className="img-fluid" />
            </div>
          </div>
        </Col>
        <Col lg="3" sm="6" className="my-2">
          <div className="CstmCard px-3 py-2 d-flex align-items-center gap-10 justify-content-between h-100">
            <div className="content">
              <p className="m-0 theme-blue">Total story updated</p>
              <p className="m-0 theme-blue">300 Dummy</p>
              {/* <h6 className="m-0 theme-blue fw-bold">{storyTotalCount}</h6> */}
            </div>
            <div className="imgWrp">
              <img src={storyImage} alt="" className="img-fluid" />
            </div>
          </div>
        </Col>
        <Col lg="3" sm="6" className="my-2">
          <div className="CstmCard px-3 py-2 d-flex align-items-center gap-10 justify-content-between h-100">
            <div className="content">
              <p className="m-0 theme-blue">Revenue</p>
              <h6 className="m-0 theme-blue fw-bold ">${"1,00,000"}</h6>
            </div>
            <div className="imgWrp">
              <img src="/assets/images/c4.png" alt="" className="img-fluid" />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CounterCards;
