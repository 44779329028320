import { createSlice } from "@reduxjs/toolkit";
import {  getSinglePage } from "./thunk";

const initialState = {
  cmsSingle: [],
  loading: false,
  error: null,
};

const singlecmsSlice = createSlice({
  name: "cmsSingle",
  initialState : {
    cmsSingle: [],
    loading: false,
    error: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder    
    //ya wala slice single cms ko get krne k lia tha 
      .addCase(getSinglePage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSinglePage.fulfilled, (state, action) => {
        state.loading = false;
        state.cmsSingle = action.payload.data;
      })
      .addCase(getSinglePage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })      
       

  }
});

export const { } = singlecmsSlice.actions;

export default singlecmsSlice.reducer;
