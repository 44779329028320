import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SingleUserProfile, SingleUserStory, UserStoryReports } from "../../../../redux/states/user/thunk";
import { Button, Table } from "react-bootstrap";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import MyPagination from "../../../../components/common/myPagination";
import NonAuthLayout from "../../../../Layout/NonAuthLayout";
import "./index.css"
import { useNavigate } from "react-router-dom";

function SingleUser() {
  const { _id: otherUserId } = useParams();
  const dispatch = useDispatch();
  const { userProfile, userStories, userReports, loading, error, totalCount } = useSelector(
    (state) => state.users
  );
  const [paginationDetails, setPaginationDetails] = useState({ page: 1, limit: 10 });
    const navigate = useNavigate();
  

  // Fetch user profile
  useEffect(() => {
    dispatch(SingleUserProfile(otherUserId));
  }, [dispatch, otherUserId]);

  // Fetch user stories with pagination
  useEffect(() => {
    dispatch(SingleUserStory({ otherUserId, ...paginationDetails }));
  }, [dispatch, otherUserId, paginationDetails]);

  // Fetch user reports
  useEffect(() => {
    dispatch(UserStoryReports({ otherUserId, page: 1, limit: 20 }));
  }, [dispatch, otherUserId]);


    const handleReadStory = (_id) => {
      navigate(`/read-story/${_id}`);
    };


  return (
    <>
  <NonAuthLayout>
  <div className="container mt-5">
    <div className="row justify-content-center">
      <div className="col-md-12 text-center">
      <Table striped bordered hover responsive>
  <thead>
    <tr>
      <th>Profile</th>
      <th>User Name</th>
      <th>User Email</th>
      <th>User Bio</th>
      <th>User Register Date</th>
      <th>User ID</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <img
          src={`${userProfile?.avatar?.url}`}
          alt={userProfile?.name?.slice(0, 6)}
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            border: "1px solid black",
          }}
        />
      </td>
      <td>{userProfile?.name || "Not Updated"}</td>
      <td>{userProfile?.email|| "Not Updated"}</td>
      <td>{userProfile?.bio || "Not Updated"}</td>
      <td>{new Date(userProfile?.createdAt).toLocaleDateString() || "Not Updated"}</td>
      <td>{userProfile?._id || "Not Updated"}</td>
    </tr>
  </tbody>
</Table>

      </div>
    </div>

    <hr className="my-4 whiteline" />
    <div className="row">
      <div className="col-md-12">
        <h3 className="mb-4 fw-bold heading">User Posts:</h3>
        {userStories?.length ? (
          <Table striped bordered hover responsive>
          <thead>
              <tr>
                <th>Title</th>
                <th>Category</th>
                <th>Special Name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {userStories.map((post) => (
                <tr key={post.id || "Not Updated"}>
                  <td>{post.title || "Not Updated"}</td>
                  <td>{post.category.name || "Not Updated"}</td>
                  <td>{post.category.specialName || "Not Updated"}</td>
                  <td>
                    {post.status === "active" ? (
                      <span style={{ color: "green" }}>
                        <FaRegEye />
                      </span>
                    ) : (
                      <span style={{ color: "red" }}>
                        <FaRegEyeSlash />
                      </span>
                    )}
                  </td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => handleReadStory(post._id)}
                    >
                      Read Story
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p className="data text-center">No posts available</p>
        )}
        <MyPagination
          paginationDetails={paginationDetails}
          setPaginationDetails={setPaginationDetails}
          totalPosts={totalCount}
        />
      </div>
    </div>

    <hr className="my-4 whiteline" />
    <div className="row">
      <div className="col-md-12">
        <h3 className="mb-4 fw-bold heading">User Reports:</h3>
        {userReports?.length ? (
          <Table striped bordered hover responsive>
          <thead>
              <tr>
                <th>Title</th>
                <th>Body</th>
              </tr>
            </thead>
            <tbody>
              {userReports.map((report) => (
                <tr key={report.id}>
                  <td>{report.title}</td>
                  <td>{report.body}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p className="data text-center">No user reports available</p>
        )}
      </div>
    </div>
    <hr className="my-4 whiteline" />
  </div>
</NonAuthLayout>
    </>
  );
}

export default SingleUser;
