import { createSlice } from "@reduxjs/toolkit";
import { SingleStoryReactions } from "./thunk";

const initialState = {
    reactions:[],
    loading: false,
  error: null,
  totalCount:[]
};

const storyReactionSlice = createSlice({
  name: "reactions",
  initialState : {
    reactions:[],
    loading: false,
    error: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
          // ya wala slice Single Story k Reactions get krne k lia tha 
          .addCase(SingleStoryReactions.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(SingleStoryReactions.fulfilled, (state, action) => {
            state.loading = false;
            state.reactions = action.payload.records || [];
            state.totalCount = action.payload.totalCount || 0;
          })
          .addCase(SingleStoryReactions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })   

  }
});

export const { } = storyReactionSlice.actions;

export default storyReactionSlice.reducer;
