import { createSlice } from "@reduxjs/toolkit";
import { getAllUsers, SingleUserProfile, SingleUserStory, UserStoryReports, UserBlockorUnblock } from "./thunk";

const initialState = {
  users: [],
  loading: false,
  error: null,
  totalCount: 0,
  selectedStatuses: {},
  selectedGenders: {},
  userProfile: null,
  userStories: [],
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setSelectedStatuses: (state, action) => {
      state.selectedStatuses = action.payload;
    },
    setSelectedGenders: (state, action) => {
      state.selectedGenders = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.data.records || [];
        state.totalCount = action.payload.data.totalCount || 0;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //aab sa SingleUserProfile ka slice start
      .addCase(SingleUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SingleUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.userProfile = action.payload;
      })
      .addCase(SingleUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //aab sa SingleUserStory ka slice start
      .addCase(SingleUserStory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SingleUserStory.fulfilled, (state, action) => {
        state.loading = false;
        state.userStories = action.payload.records  || "Failed to fetch user  reports.";
        state.totalCount = action.payload.totalCount || 0;
        // console.error("SingleUserStory fulfilled :", action.payload);

      })
      .addCase(SingleUserStory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        // console.error("SingleUserStory rejected :", action.payload);

      })
      //aab sa UserStoryReports ka slice start
      .addCase(UserStoryReports.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UserStoryReports.fulfilled, (state, action) => {
        state.loading = false;
        state.userReports = action.payload;
        // console.error("UserStoryReports fulfilled :", action.payload);

      })
      .addCase(UserStoryReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload  || "Failed to fetch user story reports.";
        // console.error("UserStoryReports Error:", action.payload);
      })
      //aab sa UserBlockorUnblock ka slice start
      .addCase(UserBlockorUnblock.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UserBlockorUnblock.fulfilled, (state, action) => {
        state.loading = false;
        state.users = state.users.map((user) =>
          user._id === action.meta.arg.targetUserId
            ? { ...user, status: action.meta.arg.currentStatus === "active" ? "blocked" : "active" }
            : user
        );
      })
      .addCase(UserBlockorUnblock.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});




export const { setSelectedStatuses, setSelectedGenders } = userSlice.actions;

export default userSlice.reducer;
