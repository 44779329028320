import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateCategorys, UpdateCategory } from "../../../../redux/states/Category/thunk";
import NonAuthLayout from "../../../../Layout/NonAuthLayout";
import { useNavigate, useParams } from "react-router-dom";

const CreateCategory = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [iconRegular, setIconRegular] = useState("");
  const [iconFilled, setIconFilled] = useState("");
  const [specialName, setSpecialName] = useState("");
  const [isPrimary, setIsPrimary] = useState(false);
  const { category, loading, error } = useSelector((state) => state.category);
  const navigate = useNavigate();
  const { id: categoryId } = useParams(); 
  
  useEffect(() => {
    if (categoryId) {
      const categoryData = category.find((cat) => cat._id === categoryId);
      if (categoryData) {
        setName(categoryData.name || "");
        setIconRegular(categoryData.iconRegular || "");
        setIconFilled(categoryData.iconFilled || "");
        setSpecialName(categoryData.specialName || "");
        setIsPrimary(categoryData.isPrimary || false);
      }
    } else {
      setName("");
      setIconRegular("");
      setIconFilled("");
      setSpecialName("");
      setIsPrimary(false);
    }
  }, [categoryId, category]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const categoryData = {
      categoryId: categoryId,
      name,
      iconRegular,
      iconFilled,
      specialName,
      isPrimary,
    };

    if (categoryId) {
      dispatch(UpdateCategory({ _id: categoryId, ...categoryData }))
        .unwrap()
        .then(() => {
          console.log("Category updated successfully");
          navigate("/manage-category");
        })
        .catch((err) => {
          console.error("Error updating category:", err);
        });
    } else {
      dispatch(CreateCategorys(categoryData))
        .unwrap()
        .then(() => {
          setName("");
          setIconRegular("");
          setIconFilled("");
          setSpecialName("");
          setIsPrimary(false);
          navigate("/manage-category");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
<>

<NonAuthLayout>
  <div className="container mt-5">
    <div className="row justify-content-center">
      <div className="col-12 col-md-12">
        <h3>{categoryId ? "Update Category" : "Create Category"}</h3>
        {loading ? (
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="categoryName" className="form-label">
                Category Name
              </label>
              <input
                type="text"
                className="form-control"
                id="categoryName"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter category name"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="iconRegular" className="form-label">
                Icon Regular
              </label>
              <input
                type="text"
                className="form-control"
                id="iconRegular"
                value={iconRegular}
                onChange={(e) => setIconRegular(e.target.value)}
                placeholder="Enter icon regular"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="iconFilled" className="form-label">
                Icon Filled
              </label>
              <input
                type="text"
                className="form-control"
                id="iconFilled"
                value={iconFilled}
                onChange={(e) => setIconFilled(e.target.value)}
                placeholder="Enter icon filled"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="specialName" className="form-label">
                Special Name
              </label>
              <input
                type="text"
                className="form-control"
                id="specialName"
                value={specialName}
                onChange={(e) => setSpecialName(e.target.value)}
                placeholder="Enter special name"
              />
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="isPrimary"
                checked={isPrimary}
                onChange={(e) => setIsPrimary(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="isPrimary">
                Is Primary
              </label>
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading
                ? "Saving..."
                : categoryId
                ? "Update Category"
                : "Create Category"}
            </button>
          </form>
        )}
        {error && <div className="alert alert-danger mt-3">{error}</div>}
      </div>
    </div>
  </div>
</NonAuthLayout>
</>
  );
};

export default CreateCategory;
