// redux/states/auth/thunk.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const staffLogin = createAsyncThunk(
  "auth/login",
  async (userDetails, { rejectWithValue }) => {
    try {
      const body = { ...userDetails, loginType: "normal" };
      const response = await axios.post(`auth/login`, body);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { message: "Login failed. Please try again." }
      );
    }
  }
);


export const register = createAsyncThunk(
  "auth/register",
  async (userDetails, Thunk) => {
    try {
      const response = await axios.post(`register`, userDetails);
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const sendOtp = createAsyncThunk(
  "auth/sendotp",
  async (email, Thunk) => {
    try {
      const response = await axios.post(`sendotp`, email);
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const resetStaffPassword = createAsyncThunk(
  "auth/resetStaffPassword",
  async (userDetails, Thunk) => {
    try {
      const response = await axios.post(`resetStaffPassword`, userDetails);
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (_, Thunk) => {
  try {
    const response = await axios.post("user/logout");
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const setPassword = createAsyncThunk(
  "auth/setPassword",
  async (details, Thunk) => {
    try {
      const response = await axios.post("setPassword", details);
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

// export const getStaffProfile = createAsyncThunk(
//   "auth/getStaffProfile",
//   async (details, Thunk) => {
//     try {
//       const response = await axios.post("getStaffProfile", details);
//       return response?.data;
//     } catch (error) {
//       return Thunk.rejectWithValue(error);
//     }
//   }
// );

export const changeStaffPassword = createAsyncThunk(
  "auth/changeStaffPassword",
  async (details, Thunk) => {
    try {
      const response = await axios.post("changeStaffPassword", details);
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
