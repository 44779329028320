import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Table, Row, Form } from "react-bootstrap";
import { getAllCategory, DeleteCategory } from "../../../redux/states/Category/thunk";
import NonAuthLayout from "../../../Layout/NonAuthLayout";
import { format_date, parseCamelKey } from "../../../utils/parseKey";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "./../../../ScrollToTop";
import MyPagination from "../../../components/common/myPagination";
import { Box, Button, Text, useToast,  } from "@chakra-ui/react";
import "./Component/Index.css"
import { confirmAlert } from "react-confirm-alert";
import { MdUpdate } from "react-icons/md";
import { IoIosCreate } from "react-icons/io";
import { MdDelete } from "react-icons/md";




const ManageCategory = () => {
  const dispatch = useDispatch();
  const { category, loading, error } = useSelector((state) => state.category);
  const navigate = useNavigate();

  const fetchCategory = async () => {
    dispatch(getAllCategory());
  };

  useEffect(() => {
    fetchCategory();
  }, [dispatch]);



  const handleCreateCategory = () => {
    navigate(`/create-category`);
  };

  const handleUpdate = (_id) => {
    navigate(`/update-category/${_id}`);
  };

  const handleDelete = (_id) => {
      confirmAlert({
        title: "Confirm Action",
        message: "Are you sure you want to delete this category?",
        buttons: [
          {
            label: "Yes",
            onClick: () => 
              dispatch(
              DeleteCategory(_id ),
            ),
          },
          {
            label: "No",
          },
        ],
      });
    };

  return (
   <>
   <NonAuthLayout>
      <section className="manageStories position-relative py-3">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="filterWrp mb-3 px-lg-5 px-3 d-flex align-items-center justify-content-between gap-10">
              <h2 className="m-0 fw-bold">Manage  Category</h2>
              <button
                className="button-create-category"
                variant="danger"
                onClick={handleCreateCategory}
              >
               <IoIosCreate className="create-icon" />
              </button>
              </div>
            </Col>

            <Col>
              {category?.length > 0 ? (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Category Name</th>
                      <th>Special Name</th>
                      <th>Category Icons</th>
                      <th>isPrimary</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {category?.map((cate, idx) => (
                      <tr key={cate._id}>
                        <td>{idx + 1 || "N/A"}</td>
                        <td>{cate?.name || "N/A"}</td>
                        <td>{cate?.specialName || "N/A"}</td>
                        <td>
                          <Box display="flex">
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: cate?.iconRegular || "",
                              }}
                              style={{padding:"2px"}}
                            />
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: cate?.iconFilled || "",
                              }}
                              style={{padding:"2px"}}
                            />
                          </Box>
                        </td>
                        <td>{cate?.isPrimary ? "Yes" : "No"}</td>
                        <td>
                          <button
                           className="button-update-category"
                           style={{
                           backgroundColor: cate.status === "active" ? "rgb(220, 73, 73)" : "rgb(6, 184, 6)" 
                            }}
                            variant="danger"
                            onClick={() => handleUpdate(cate._id)}>
                            <MdUpdate className="update-icon" />
                            </button>
                          <button
                           className="button-delete-category"
                            variant="danger"
                            onClick={() => handleDelete(cate._id)}>
                            <MdDelete  className="delete-icon"/>
                            </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h4 className="my-5 text-center w-100">Not Data Found</h4>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </NonAuthLayout>
</>
  );
};

export default ManageCategory;
