import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateQuriesStatus, ReadQuries } from "../../../../redux/states/Quries/thunk";
import NonAuthLayout from "../../../../Layout/NonAuthLayout";
import { useNavigate, useParams } from "react-router-dom";
import "./Index.css"

const UpdateQuries = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: queryId } = useParams();
  const {loading, error } = useSelector((state) => state.quries);
  const [reply, setReply] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");

// const fetchQuries = async () => {
//           if (queryId) {
//             dispatch(ReadQuries(queryId));
//           } else {
//             console.error("Query ID is undefined.");
//           }
//         };
      
//         useEffect(() => {
//           fetchQuries();
//         }, [dispatch]);

  // useEffect(() => {
  //   if (queryId) {
  //     const queryData = {
  //       reply: quries.reply,
  //       status: quries.status,
  //     };
  //     setReply(queryData.reply);
  //     setUpdateStatus(queryData.status);
  //   }
  // }, [queryId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const queryData = {
      queryId,
      reply,
      status: updateStatus,
    };
    try {
      await dispatch(UpdateQuriesStatus(queryData)).unwrap();
      navigate("/manage-quries"); 
    } catch (err) {
      console.error("Error updating query:", err);
    }
  };

  return (
    <NonAuthLayout>
  <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card shadow p-4">
            <h3 className="card-title text-center mb-4">Update Queries</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-3">
                <label htmlFor="reply" className="form-label">
                  Reply
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="reply"
                  value={reply}
                  onChange={(e) => setReply(e.target.value)}
                  placeholder="Enter your reply"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="status" className="form-label">
                  Status
                </label>
                <select
                  className="form-select"
                  id="status"
                  value={updateStatus}
                  onChange={(e) => setUpdateStatus(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select status
                  </option>
                  <option value="active">Active</option>
                  <option value="closed">Closed</option>
                  <option value="resolved">Resolved</option>
                  <option value="fulfilled">Fulfilled</option>
                </select>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary px-4"
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Update Query"
                  )}
                </button>
              </div>
              {error && (
                <div className="alert alert-danger mt-3 text-center">
                  {error}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
    </NonAuthLayout>
  );
};

export default UpdateQuries;
