// redux/states/auth/slice.js
import { createSlice } from "@reduxjs/toolkit";
import { staffLogin } from "./thunk";

const initialState = {
  admin: null,
  isAuthenticated: false,
  rememberedUser: null,
  staffDetails: {},
  loading: false,
};
const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    logout: (state) => {
      state.admin = null;
      state.isAuthenticated = false;
      state.rememberedUser = null;
      state.staffDetails = {}; 
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(staffLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(staffLogin.fulfilled, (state, action) => {
        state.admin = action.payload;
        state.isAuthenticated = true;
        state.rememberedUser = action.meta?.arg.remember
          ? action.meta.arg
          : state.rememberedUser;
        state.loading = false;
      })
      .addCase(staffLogin.rejected, (state) => {
        state.isAuthenticated = false;
        state.loading = false;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
