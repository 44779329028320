import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NonAuthLayout from "../../../Layout/NonAuthLayout";
import { Col, Container, Row } from "react-bootstrap";
import CounterCards from "./component/CounterCards";
import BarChart from "./component/Chart/BarChart";


const Dashboard = () => {

  return (
    <>
      <NonAuthLayout>
        <section className="dashboard py-3"></section>
        <Container fluid>
          <CounterCards />
          <Row>
            <Col lg="6" className="my-2">
              <div className="Box cardCstm h-100 p-3">
                <div className="topHead d-flex align-items-start justify-content-between gap-10">
                  <div className="left">
                    <div className="d-flex align-items-center">
                      <h6 className="m-0 fw-bold theme-blue">Users</h6>
                    </div>
                    <p className="m-0 text-muted m-0">
                      Below is total users registered across Time
                    </p>
                  </div>
                </div>
                <div className="GraphBody pt-3">
                <BarChart />
                </div>
              </div>
            </Col>
            <Col lg="6" className="my-2">
              <div className="Box cardCstm h-100 p-3">
                <div className="topHead d-flex align-items-start justify-content-between gap-10">
                  <div className="left">
                    <div className="d-flex align-items-center">
                      <h6 className="m-0 fw-bold theme-blue">Notifications</h6>
                    </div>
                    <p className="m-0 text-muted m-0">
                      Below is Notifications Section for sending users to Alerts Messages
                    </p>
                  </div>
                </div>
                <div className="GraphBody pt-3">
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </NonAuthLayout>
    </>
  );
};

export default Dashboard;
